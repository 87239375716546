import Vue from 'vue'
import {
  Button,
  Form,
  Field,
  Popup,
  Area,
  Picker,
  Notify,
  CellGroup,
  DatetimePicker,
  RadioGroup,
  Radio,
  Cascader,
  NavBar,
  Toast,
  Checkbox,
  CheckboxGroup,
  Cell
} from 'vant'

Vue.use(Cascader)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(DatetimePicker)
Vue.use(CellGroup)
Vue.use(Notify)
Vue.use(Picker)
Vue.use(Area)
Vue.use(Popup)
Vue.use(Field)
Vue.use(Form)
Vue.use(Button)
Vue.use(NavBar)
Vue.use(Toast)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Cell)
