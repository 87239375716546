import Vue from 'vue'
import Vuex from 'vuex'
import { verificationApi } from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title: '', // 训练营名称
    periods: null, // 期数
    roleDesc: '', // 角色
  },
  getters: {},
  mutations: {
    SET_TITLE (state, val) {
      state.title = val
    },
    SET_PERIODS (state, val) {
      state.periods = val
    },
    SET_ROLEDESC (state, val) {
      state.roleDesc = val
    }
  },
  actions: {
    Verification ({ commit }, value) {
      return new Promise((resolve, reject) => {
        const role = Number(JSON.parse(decodeURI(value.query.role)))
        verificationApi({
          camp_id: Number(JSON.parse(decodeURI(value.query.camp_id))),
          role: role
        })
          .then((response) => {
            commit('SET_TITLE', response.data.type_zh)
            commit('SET_PERIODS', response.data.periods)
            const confine = response.data.confine || []
            const a = confine.find((item) => item.role === role) || {}
            commit('SET_ROLEDESC', a.name || '')
            if (response.data.status === 1) {
              resolve(response.data)
            } else {
              reject(response.data)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  modules: {}
})
