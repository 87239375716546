import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'eventApply',
    component: () => import(/* webpackChunkName: "form" */ '@/views/apply/eventApply.vue')
  },
  {
    path: '/secrectPage',
    name: 'SecrectPage',
    component: () => import(/* webpackChunkName: "form" */ '@/views/form/SecrectPage.vue')
  },
  {
    path: '/success',
    name: 'Success',
    component: () =>
      import(/* webpackChunkName: "success" */ '@/views/success')
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: "error" */ '@/views/apply/error')
  },
  {
    path: '/early',
    name: 'Early',
    component: () => import(/* webpackChunkName: "error" */ '@/views/apply/EarlyPage.vue')
  },
  {
    path: '/signature',
    name: 'Signature',
    component: () => import(/* webpackChunkName: "signature" */ '@/views/signature')
  },
  {
    path: '/signSuccess',
    name: 'SignSuccess',
    component: () => import(/* webpackChunkName: "signature" */ '@/views/signature/success.vue')
  },
  {
    path: '/eventApply',
    name: 'eventApply',
    component: () => import(/* webpackChunkName: "signature" */ '@/views/apply/eventApply.vue')
  },
  {
    path: '/applySuccess',
    name: 'applySuccess',
    component: () => import(/* webpackChunkName: "signature" */ '@/views/apply/applySuccess.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
