import axios from 'axios'

const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL2, // url = base url + request url
  timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (response.status === 200 && res.code !== 200) {
      return Promise.reject(res)
    } else {
      return Promise.resolve(res)
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default service
